import React, { useCallback, useEffect } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { FormValues, ResourceForm } from './ResourceForm';
import { useDispatch } from 'react-redux';
import { isDir, isFile, DirResource } from '../../../../../modules/resources';
import {
  useDirsActions,
  useDirsFormState,
  useDirSiblings
} from '../../../../../modules/dirs/hooks';
import { FormikBag } from 'formik';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
  resource: DirResource;
}

export const DirectoryFormModal = ({ resource, open, onClose }: Props) => {
  const dispatch = useDispatch();
  const fullScreen = useShouldModalBeFullscreen();
  const { pathUpdateDir, clearErrors } = useDirsActions();
  const { didUpdate, errors } = useDirsFormState();

  const siblings = useDirSiblings(resource.id);
  let form = React.useRef<FormikBag<any, FormValues>>();

  useEffect(() => {
    if (didUpdate) {
      onClose();
    }

    if (errors && form.current) {
      form.current.setErrors(errors);
      form.current.setSubmitting(false);
    }

    return () => {
      clearErrors();
    };
    // eslint-disable-next-line
  }, [didUpdate, errors]);

  const onSubmit = useCallback(
    (values: FormValues, formBag) => {
      form.current = formBag;

      if (isDir(resource)) {
        pathUpdateDir({
          id: resource.id,
          title: values.name
        });
      }
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const getInitialValues = () => {
    if (isDir(resource)) {
      return {
        name: resource.title
      };
    }

    if (isFile(resource)) {
      return {
        // @ts-ignore
        name: resource.filename
      };
    }

    return {};
  };

  const validate = (values: FormValues) => {
    if (siblings && siblings.some(item => item.title === values.name.trim())) {
      return {
        name: 'This name already exists'
      };
    }
  };

  return (
    <Dialog
      aria-labelledby="directory-form-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <DialogContent>
        <ModalClose onClose={onClose} />
        <ResourceForm
          onCancel={onClose}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={getInitialValues()}
        />
      </DialogContent>
    </Dialog>
  );
};
