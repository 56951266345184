export const AUTH_PREFIX = '@AUTH/';

export const ACCESS_TOKEN_KEY = '__quodoc_access-token__';
export const REFRESH_TOKEN_KEY = '__quodoc_refresh-token__';

export const LOGIN = AUTH_PREFIX + 'LOGIN';
export const LOGOUT = AUTH_PREFIX + 'LOGOUT';
export const REFRESH_TOKEN = AUTH_PREFIX + 'REFRESH_TOKEN';
export const FETCH_USER = AUTH_PREFIX + 'FETCH_USER';

export const ROLES = {
  MOBILE_APP_USER: 'ROLE_MOBILE_APP_USER',
  USER: 'ROLE_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
};
