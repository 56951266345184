import React, { createRef, useMemo, useCallback } from 'react';
import {
  useDirsActions,
  useLoadedDirs
} from '../../../../../modules/dirs/hooks';
import { SortableTree } from 'components';
import { map, getTreeFromFlatData } from 'react-sortable-tree';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DirectoryLabel } from './DirectoryLabel';
import { Link } from 'react-router-dom';
import FolderIcon from '@material-ui/icons/Folder';
import { makeStyles, Theme } from '@material-ui/core';
import { Dir } from '../../../../../modules/dirs';
import { useUserHasRoles } from '../../../../../modules/auth/hooks';
import { ROLES } from '../../../../../modules/auth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}));

export function DirectoryTree() {
  const classes = useStyles();
  const { fetchDirs, readDir, moveDir } = useDirsActions();
  const [expanded, setExpanded] = React.useState<Dir[]>([]);
  const { dirs: flatData, isLoading } = useLoadedDirs();
  const canDrag = useUserHasRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPER_ADMIN]);
  const containerRef = createRef<HTMLDivElement>();

  const treeData = useMemo(() => {
    return getTreeFromFlatData({
      // @ts-ignore
      flatData,
      getParentKey: node => {
        // @ts-ignore
        return node.parentId || '0';
      }
    });
  }, [flatData]);

  const { data, visibleLength } = useMemo(() => {
    let visibleLength = treeData.length;

    const data = map({
      treeData,
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
      callback: ({ node }: any) => {
        if (expanded.find(item => item.id === node.id)) {
          node.expanded = true;
          if (node.children) {
            visibleLength += node.children.length;
          }
        }

        return node;
      }
    });

    return { data, visibleLength };
  }, [treeData, expanded]);

  const handleNodeExpand = (dir: Dir) => {
    readDir({ id: dir.id });
    fetchDirs({
      parentId: dir.id
    });
  };

  const collectNested = (item: Dir): number[] => {
    return [item.id].concat(...(item.children || []).map(collectNested));
  };

  const handleNodeCollapse = (dir: Dir) => {
    const children = collectNested(dir);

    setExpanded(prev => prev.filter(item => !children.includes(item.id)));
  };

  const handleDirMove = useCallback(
    ({ node, nextParentNode, treeData }: any) => {
      if (nextParentNode) {
        setExpanded(prev => [...prev, nextParentNode]);
      }

      let position = -1;
      const nodes = nextParentNode ? nextParentNode.children : treeData;
      nodes.find((item: Dir) => {
        position++;
        return +item.id === +node.id;
      });

      const siblings: any = {};
      let siblingPosition = -1;
      nodes.forEach((node: Dir) => {
        siblingPosition++;
        siblings[node.id] = siblingPosition;
      });

      moveDir({
        id: node.id,
        parent: nextParentNode ? nextParentNode.id : null,
        position,
        siblings
      });
    },
    [moveDir]
  );

  if (isLoading) {
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={classes.root} ref={containerRef}>
      <SortableTree
        visibleLength={visibleLength}
        isVirtualized
        treeData={data}
        canDrag={canDrag}
        onVisibilityToggle={({ node, expanded }) => {
          if (expanded) {
            setExpanded(prev => [...prev, node as Dir]);
            handleNodeExpand(node as Dir);
          } else {
            handleNodeCollapse(node as Dir);
          }
        }}
        onMoveNode={handleDirMove}
        getNodeKey={({ node }) => node.id}
        generateNodeProps={() => ({
          collapseIcon: ExpandMoreIcon,
          expandIcon: ChevronRightIcon,
          nodeHasChildren: (node: Dir) => {
            if (!node) {
              return false;
            }

            if (node.children) {
              return node.children.length > 0;
            }

            return node.childrenCount > 0;
          },
          title: ({ node, isDragging }: any) => (
            <DirectoryLabel>
              {isDragging ? (
                <>
                  <FolderIcon color="inherit" className={classes.icon} />
                  <span>{node.title}</span>
                </>
              ) : (
                <Link to={`/directory/${node.id}`}>
                  <FolderIcon color="inherit" className={classes.icon} />
                  <span>{node.title}</span>
                </Link>
              )}
            </DirectoryLabel>
          )
        })}
      />
    </div>
  );
}
