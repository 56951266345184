import React, { useRef } from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { Dir } from '../../../../../modules/dirs';
import { useTranslation } from 'react-i18next';
import { useSingleDir } from '../../../../../modules/dirs/hooks';
import { IntelligentLayerForm } from './IntelligentLayerForm';
import { IntelligentLayerValues } from './IntelligentLayerValues';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
  dir: Dir;
}

export const IntelligentLayerModal = ({
  open,
  onClose,
  dir: dirResource
}: Props) => {
  const { dir } = useSingleDir(dirResource.id);
  const { t } = useTranslation('myDisk');
  const fullScreen = useShouldModalBeFullscreen();
  const contentRef = useRef<HTMLDivElement>(null);

  if (!dir) {
    return null;
  }

  const handleError = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Dialog
      aria-labelledby="dir-intelligent-layer-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md">
      <DialogContent ref={contentRef}>
        <ModalClose onClose={onClose} />
        <Typography align="center" gutterBottom variant="h3">
          {t('Intelligence Layer')}
        </Typography>
        <IntelligentLayerForm dir={dir} />
        {dir.isIntelligentLayerEnabled && (
          <IntelligentLayerValues
            dir={dir}
            onCancel={onClose}
            onSubmitError={handleError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
