import React, { useEffect, useState } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'utils/translation';
import { File } from '../../../../modules/files';
import { DateFormat, FileIcon } from '../../../../components';
import bytesToSize from 'utils/bytesToSize';
import { useFilesActions } from '../../../../modules/files/hooks';
import {
  useBasketActions,
  useIsFileInBasket
} from '../../../../modules/basket/hooks';
import {
  transformFileToResource,
  useResourceModal
} from '../../../../modules/resources';
import { ResourceModal } from '../../../../modules/ui';
import clsx from 'clsx';
import { PreviewModal } from './PreviewModal/PreviewModal';
import { Theme } from '../../../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1.5, 2)
  },
  preview: {
    marginBottom: theme.spacing(2)
  },
  title: {
    margin: theme.spacing(4, 0, 2, 0),
    fontWeight: 'bold'
  },
  table: {
    '& td': {
      padding: theme.spacing(1)
    }
  },
  fileIcon: {
    width: 60,
    height: 60,
    margin: '0 auto'
  },
  thumbnail: {
    width: '100%'
  },
  previewWrapper: {
    display: 'flex',
    flex: 1,
    position: 'relative'
  },
  previewOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',
    opacity: 0,
    '& $previewIcon': {
      transform: 'translateY(100%)',
      transition: 'transform 0.3s ease'
    },
    transition: 'opacity 0.15s ease',
    '&:hover': {
      opacity: 1,
      '& $previewIcon': {
        transform: 'translateY(0)'
      }
    }
  },
  previewIcon: {
    fontSize: theme.spacing(4),
    color: theme.palette.white
  }
}));

interface Props {
  file: File;
}

const FileContents: React.FC<Props> = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [hasThumb, setHasThumb] = useState<boolean>(!!file.thumbnailUrl);
  const { openModal } = useResourceModal();
  const { getThumb, getPreview, download, share } = useFilesActions();
  const { addFile, removeFile } = useBasketActions();
  const isInBasket = useIsFileInBasket(file.id);
  const supportsPreview = ['pdf', 'jpg', 'jpeg', 'gif', 'png'].includes(
    file.type
  );

  useEffect(() => {
    getThumb(file);
    // eslint-disable-next-line
  }, [file.id]);

  useEffect(() => {
    if (supportsPreview) {
      getPreview(file);
    }
    // eslint-disable-next-line
  }, [file.type, file.id]);

  useEffect(() => {
    setHasThumb(!!file.thumbnailUrl);
  }, [file.thumbnailUrl]);

  const handleAddFileToBasket = () => {
    addFile({ file: { id: file.id } });
  };

  const handleRemoveFileFromBasket = () => {
    removeFile({ file: { id: file.id } });
  };

  const handleImageError = () => {
    setHasThumb(false);
  };

  const handleFileDownload = () => {
    download(file);
  };

  const handleFileShare = () => {
    share(file);
    const resource = transformFileToResource(file);
    openModal(ResourceModal.Share, resource);
  };

  const handlePreviewShow = () => {
    if (supportsPreview) {
      setPreviewVisible(true);
    }
  };

  const handlePreviewHide = () => {
    setPreviewVisible(false);
  };

  return (
    <React.Fragment>
      <List className={classes.list} key={file.id}>
        <ListItem
          onClick={handlePreviewShow}
          className={clsx(classes.item, classes.preview)}>
          <div className={classes.previewWrapper}>
            {hasThumb ? (
              <img
                className={classes.thumbnail}
                onError={handleImageError}
                src={file.thumbnailUrl}
                alt=""
              />
            ) : (
              <FileIcon type={file.type} className={classes.fileIcon} />
            )}
            {supportsPreview && (
              <div className={classes.previewOverlay}>
                <SearchIcon className={classes.previewIcon} />
              </div>
            )}
          </div>
        </ListItem>
        <Divider />
        <ListItem button className={classes.item} onClick={handleFileDownload}>
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('Download')} />
        </ListItem>
        <Divider />
        {isInBasket ? (
          <ListItem
            button
            className={classes.item}
            onClick={handleRemoveFileFromBasket}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            <ListItemText primary={t('Remove From Collection')} />
          </ListItem>
        ) : (
          <ListItem
            button
            className={classes.item}
            onClick={handleAddFileToBasket}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={t('Add To Collection')} />
          </ListItem>
        )}
        <Divider />
        <ListItem button className={classes.item} onClick={handleFileShare}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary={t('Share')} />
        </ListItem>
        <Divider />
        <Typography variant="h4" className={classes.title}>
          {t('File info')}
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head">{t('Type')}</TableCell>
              <TableCell>{file.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Size')}</TableCell>
              <TableCell>{bytesToSize(file.size)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Created on')}</TableCell>
              <TableCell>
                <DateFormat date={file.createdAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Dimensions')}</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Resolution')}</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </List>
      {previewVisible && (
        <PreviewModal file={file} onClose={handlePreviewHide} />
      )}
    </React.Fragment>
  );
};

export { FileContents };
