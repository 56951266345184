import React, { useEffect, useRef } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  colors,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Theme,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import { dirsActions } from '../../../../../modules/dirs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

export interface FormValues {
  title: string;
}

interface OtherProps {
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
  initialValues?: Partial<FormValues>;
  isEdit?: boolean;
}

const BaseDirectoryForm = (props: OtherProps & FormikProps<FormValues>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dirs', 'validation']);
  const emailRef = useRef();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,

    isEdit,
    onCancel
  } = props;

  const {
    didCreate,
    didUpdate,
    isSubmitting,
    errors: stateErrors
  } = useSelector((state: RootState) => state.dirs);

  useEffect(() => {
    if (didCreate || didUpdate) {
      onCancel && onCancel();
    }
    // eslint-disable-next-line
  }, [didCreate, didUpdate]);

  useEffect(() => {
    if (emailRef.current) {
      // @ts-ignore
      emailRef.current.focus();
    }

    return () => {
      dispatch(dirsActions.clearErrors());
    };
    // eslint-disable-next-line
  }, []);

  const getError = (field: keyof FormValues) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }

    if (stateErrors && typeof stateErrors[field] === 'string') {
      return stateErrors[field];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography align="center" gutterBottom variant="h3">
        {t(isEdit ? 'Update Directory' : 'Create Directory')}
      </Typography>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <FormControl error={Boolean(getError('title'))} fullWidth>
            <InputLabel>{t('Title')}</InputLabel>
            <Input
              name="title"
              value={values.title}
              onChange={handleChange}
              inputRef={emailRef}
            />
            {getError('title') && (
              <FormHelperText>
                {t(`validation:${getError('title')}`)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <CardActions className={classes.actions}>
        <Button onClick={onCancel} variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          className={classes.saveButton}
          variant="contained"
          disabled={isSubmitting}>
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            t(isEdit ? 'Save' : 'Create')
          )}
        </Button>
      </CardActions>
    </form>
  );
};

const buildValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('Required')
  });
};

export const DirectoryForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      title: initialValues.title || ''
    };
  },
  validationSchema: buildValidationSchema,
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values);
  }
})(BaseDirectoryForm);

DirectoryForm.defaultProps = {
  isEdit: false
};
