export interface User {
  email: string;
  id: number;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export * from './components/HasRole';
export * from './components/CurrentUserActions';
export { ROLES } from './constants';
