import React from 'react';
import { useTranslation } from 'utils/translation';
import LayersIcon from '@material-ui/icons/Layers';
import { Dir } from '../../../../../modules/dirs';
import {
  Grid,
  Switch,
  Theme,
  Typography,
  makeStyles,
  FormControlLabel
} from '@material-ui/core';
import { useDirsActions } from '../../../../../modules/dirs/hooks';

interface Props {
  dir: Dir;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  switcher: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 0)
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  icon: {
    fontSize: theme.spacing(6),
    margin: theme.spacing(1, 0)
  }
}));

const IntelligentLayerInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');

  return (
    <Grid item className={classes.info}>
      <LayersIcon className={classes.icon} />
      <Typography>{t('This folder has Intelligence Layer enabled')}</Typography>
    </Grid>
  );
};

const IntelligentLayerForm: React.FC<Props> = ({ dir }) => {
  const { pathUpdateDir } = useDirsActions();
  const classes = useStyles();
  const { t } = useTranslation('myDisk');

  const handleDirSettingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    pathUpdateDir({
      id: dir.id,
      isIntelligentLayerEnabled: checked
    });
  };

  const label = dir.isIntelligentLayerEnabled
    ? 'Disable Intelligence Layer'
    : 'Enable Intelligence Layer';

  return (
    <div className={classes.root}>
      {dir.isIntelligentLayerEnabled && <IntelligentLayerInfo />}
      <div className={classes.switcher}>
        <FormControlLabel
          label={t(label)}
          control={
            <Switch
              checked={dir.isIntelligentLayerEnabled}
              onChange={handleDirSettingChange}
              value={dir.isIntelligentLayerEnabled}
              color="primary"
            />
          }
        />
      </div>
    </div>
  );
};

export { IntelligentLayerForm };
