import { eventChannel, END } from 'redux-saga';
import { upload } from '../service/api';
import axios from 'axios';

export function createUploadFileChannel(file: File, dirId?: number) {
  return eventChannel(emitter => {
    const source = axios.CancelToken.source();

    const onProgress = ({ progress }: any) => {
      emitter({ progress });
    };

    upload({
      file,
      dirId,
      onUploadProgress: onProgress,
      cancelToken: source.token
    })
      .then(response => {
        emitter({
          success: true,
          response
        });
        emitter(END);
      })
      .catch(error => {
        emitter({ error });
        emitter(END);
      });

    return () => {
      source.cancel('Upload canceled');
    };
  });
}
