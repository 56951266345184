import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'utils/translation';
import {
  useDirsActions,
  useLoadedDirs
} from '../../../../../modules/dirs/hooks';
import { Button, CardActions, Typography, colors } from '@material-ui/core';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { TreeSelect } from '../../../../../components';
import { Dir } from '../../../../../modules/dirs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 300
  },
  divider: {
    height: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

interface MoveFormProps {
  onCancel: () => void;
  onSubmit: (dirId: number) => void;
  title: string;
  filter?(dir: Dir): boolean;
}

export const MoveForm: React.FC<MoveFormProps> = ({
  onCancel,
  onSubmit,
  title,
  filter
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const [dir, setDir] = React.useState<Nullable<Dir>>(null);
  const { dirs } = useLoadedDirs();
  const { fetchDirs } = useDirsActions();
  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  useEffect(() => {
    dirs
      .filter(dir => !dir.parentId)
      .map(dir => fetchDirs({ parentId: dir.id }));
    // eslint-disable-next-line
  }, []);

  let flatData = dirs || [];

  if (filter) {
    flatData = flatData.filter(filter);
  }

  flatData = flatData.map(node => {
    const pathsIds = Array.from(node.path || []).map((node: Dir) => node.id);
    return {
      ...node,
      label: node.title,
      expanded: pathsIds.every(id => expandedIds.includes(id)),
      checked: dir && dir.id === node.id
    };
  });

  const treeData = getTreeFromFlatData({
    // @ts-ignore
    flatData: flatData,
    getParentKey: node => {
      // @ts-ignore
      return node.parentId || '0';
    }
  });

  treeData.unshift({
    id: 0,
    label: t('My Disk'),
    checked: dir && dir.id === 0
  });

  const handleSubmit = () => {
    if (dir) {
      onSubmit(dir.id);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSelectedDirChange = (dir: Dir | any, nodes: any[]) => {
    if (nodes.length) {
      setDir(dir as Dir);
      fetchDirs({
        parentId: dir.id
      });
    } else {
      setDir(null);
    }
  };

  const handleNodeToggle = (node: any) => {
    if (expandedIds.includes(node.id)) {
      setExpandedIds(prev => prev.filter(id => id !== node.id));
    } else {
      setExpandedIds(prev => [...prev, node.id]);

      if (node.childrenIds) {
        Array.from(node.childrenIds).map(id => fetchDirs({ parentId: id }));
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography align="center" gutterBottom variant="h3">
        {title}
      </Typography>
      <TreeSelect
        className="directory-selection"
        data={treeData}
        onChange={handleSelectedDirChange}
        onNodeToggle={handleNodeToggle}
        texts={{
          placeholder: t('Select directory')
        }}
      />
      <div className={classes.divider} />
      <CardActions className={classes.actions}>
        <Button variant="contained" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!dir}
          type="submit"
          className={classes.saveButton}
          onClick={handleSubmit}>
          {t('Move')}
        </Button>
      </CardActions>
    </div>
  );
};
