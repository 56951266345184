import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../constants';

type Credentials = {
  accessToken: string;
  refreshToken: string;
};

export function getCredentials() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  return {
    accessToken,
    refreshToken
  };
}

export function storeCredentials({ accessToken, refreshToken }: Credentials) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function removeCredentials() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}
