import React, { useEffect, useState } from 'react';
import { Toolbar } from '@material-ui/core';
import get from 'lodash/get';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedLoader, Page } from '../../components';
import { useTranslation } from 'utils/translation';
import { Drawer } from './components';
import { useDirsActions } from '../../modules/dirs/hooks';
import useRouter from '../../utils/useRouter';
import ResultsView from './components/Results';
import { isDir, isFile, mapResources, Resource } from '../../modules/resources';
import { useResultsViewMode } from '../../modules/ui/hooks';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';
import { ViewMode } from '../../modules/ui/types';
import useDiskStyles from './MyDisk.styles';
import { useLazyLoading } from '../../utils/use-lazy-loading';
import { File } from '../../modules/files';
import {
  useApiSearch,
  useDispatchSearchResults
} from '../../modules/search/hooks';
import { Dir } from '../../modules/dirs';
import { useFilesActions } from '../../modules/files/hooks';
import { NoSearchResults } from '../../modules/search';
import ResourceModals from './components/ResourceModals';
import MyDiskProviders from './MyDiskProviders';
import { useSortableDiskContext } from './utils/sortable.context';

const Search = () => {
  const { t } = useTranslation();
  const { readDir } = useDirsActions();
  const { readFile } = useFilesActions();
  const { viewMode, changeViewMode } = useResultsViewMode();
  const dispatchSearchResults = useDispatchSearchResults();

  const router = useRouter();
  const params = router.match.params as { search: string };
  const search = decodeURIComponent(params.search || '');
  const { response, request, isLoading } = useApiSearch();

  const dirs = get(response, 'directories', []) as Dir[];
  const files = get(response, 'files', []) as File[];
  const resources = mapResources({ dirs, files });

  const [isOpen, setOpen] = useState(false);
  const [drawerSelection, setDrawerSelection] = useState<Nullable<Resource>>(
    null
  );
  const { classes, drawerOptions } = useDiskStyles({ isOpen });
  const { sort } = useSortableDiskContext();
  const { increasePage, items } = useLazyLoading<Resource>(
    {
      items: sort(resources)
    },
    []
  );

  useEffect(() => {
    request({ query: search });
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (response && !isLoading) {
      dispatchSearchResults({
        dirs,
        files
      });
    }
    // eslint-disable-next-line
  }, [search, response, isLoading, dirs, files]);

  const handleItemClick = (item: Resource) => {
    setDrawerSelection(item);
    setOpen(true);

    if (isFile(item)) {
      readFile({
        id: item.id
      });
    }

    if (isDir(item)) {
      readDir({
        id: item.id
      });
    }
  };

  const handleYReachEnd = () => {
    increasePage();
  };

  const getResources = () => {
    if (viewMode === ViewMode.Table) {
      return resources;
    }

    return items;
  };

  return (
    <Page className={classes.root} title={t('Search results')}>
      {resources.length === 0 && isLoading && <FixedLoader />}
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <ViewSwitcher onChange={changeViewMode} value={viewMode} />
        </Toolbar>
        <div className={classes.scrollbarContainer}>
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
              wheelSpeed: 3
            }}
            onYReachEnd={handleYReachEnd}>
            <div className={classes.content}>
              {!isLoading && resources.length === 0 && (
                <NoSearchResults query={search} />
              )}
              {resources.length > 0 && (
                <ResultsView
                  viewMode={viewMode}
                  selectedResource={drawerSelection}
                  resources={getResources()}
                  onClick={handleItemClick}
                />
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <ResourceModals />
      {drawerSelection && (
        <Drawer
          resource={drawerSelection}
          open={isOpen}
          onClose={() => setOpen(false)}
          options={drawerOptions}
        />
      )}
    </Page>
  );
};

export default () => (
  <MyDiskProviders>
    <Search />
  </MyDiskProviders>
);
