import { useApiCall } from '../@common/hooks';
import { search } from './service/api';
import { File } from '../files';
import { filesActions } from '../files/actions';
import { dirsActions } from '../dirs/actions';
import { useDispatch } from 'react-redux';
import { Dir } from '../dirs';
import { useCallback } from 'react';

export function useApiSearch() {
  return useApiCall({
    provider: search
  });
}

export function useDispatchSearchResults() {
  const dispatch = useDispatch();
  const dispatchDirectories = (dirs: Dir[] = []) => {
    if (!dirs.length) {
      return;
    }

    dispatch(dirsActions.search.success({ dirs }));
  };

  const dispatchFiles = (files: File[] = []) => {
    if (!files.length) {
      return;
    }

    dispatch(filesActions.search.success({ files }));
  };

  return useCallback(
    ({ dirs, files }: { dirs: Dir[]; files: File[] }) => {
      dispatchDirectories(dirs);
      dispatchFiles(files);
    },
    // eslint-disable-next-line
    [dispatch]
  );
}
