import { User } from './index';
import {
  fetchUser,
  login,
  logout,
  refreshToken as refreshTokenAction
} from './actions';
import { get } from 'lodash';
import { getCredentials } from './service/storage';

interface AuthState {
  user: Nullable<User>;
  isLoading: boolean;
  isUserLoading: boolean;
  accessToken: Nullable<string>;
  refreshToken: Nullable<string>;
  error: Nullable<string>;
}

const { accessToken, refreshToken } = getCredentials();

const initialState: AuthState = {
  user: null,
  isLoading: false,
  isUserLoading: !!accessToken,
  accessToken: accessToken,
  refreshToken: refreshToken,
  error: null
};

export const authReducer = (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    case login.REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true
      };
    }

    case refreshTokenAction.SUCCESS:
    case login.SUCCESS: {
      const { access_token, refresh_token } = action.payload;

      if (access_token && refresh_token) {
        return {
          ...state,
          accessToken: access_token,
          refreshToken: refresh_token
        };
      }

      return state;
    }

    case login.FAILURE: {
      const status = get(action, 'meta.response.status');

      if (status === 400) {
        return {
          ...state,
          error: 'Username or password invalid'
        };
      }

      return state;
    }

    case login.FULFILL: {
      return {
        ...state,
        isLoading: false
      };
    }

    case fetchUser.REQUEST: {
      const { silent } = action.payload;

      return {
        ...state,
        isUserLoading: !silent
      };
    }

    case fetchUser.SUCCESS: {
      const user = action.payload;

      return {
        ...state,
        user
      };
    }

    case fetchUser.FAILURE: {
      const description = get(
        action.meta,
        'response.data.error_description',
        null
      );

      return {
        ...state,
        error: description
      };
    }

    case fetchUser.FULFILL: {
      return {
        ...state,
        isUserLoading: false
      };
    }

    case logout.TRIGGER: {
      return {
        ...state,
        isUserLoading: false
      };
    }

    default:
      return state;
  }
};
