import React from 'react';
import 'date-fns';
import moment from 'moment';
import get from 'lodash/get';
import pick from 'lodash/pick';
import {
  KeyboardDatePicker,
  MaterialUiPickersDate
} from '@material-ui/pickers';
import { FieldProps } from './types';

const DateField: React.FC<FieldProps> = ({
  value,
  name,
  form,
  settings = {}
}) => {
  const attributes = pick(settings, [
    'disablePast',
    'disableFeature',
    'minDateMessage',
    'maxDateMessage'
  ]);
  const format = get(settings, 'format', 'DD-MM-YYYY');
  let currentValue = null;
  try {
    if (value && !Number.isNaN(+value)) {
      const date = moment(+value * 1000);
      currentValue = date.toDate();
    }
  } catch (e) {}

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) {
      form.setFieldValue(name, (+date.startOf('day') / 1000).toString(), false);
    } else {
      form.setFieldValue(name, null, false);
    }
  };

  return (
    <KeyboardDatePicker
      {...attributes}
      variant="dialog"
      format={format}
      value={currentValue}
      onChange={handleDateChange}
    />
  );
};

export { DateField };
