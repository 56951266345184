import React from 'react';
import { useUserHasRoles } from '../hooks';
import { ROLES } from '../constants';

interface Props {
  roles: string[];
}

const HasRole: React.FC<Props> = ({ roles = [], children }) => {
  const grantAccess = useUserHasRoles(roles);

  if (grantAccess) {
    return children as React.ReactElement;
  }

  return null;
};

const SuperAdminRole: React.FC = ({ children }) => (
  <HasRole roles={[ROLES.ROLE_SUPER_ADMIN]}>{children}</HasRole>
);

const AdminRole: React.FC = ({ children }) => (
  <HasRole roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN]}>
    {children}
  </HasRole>
);

export { HasRole, AdminRole, SuperAdminRole };
