import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary
  },
  labelRoot: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  labelIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  },
  labelText: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontWeight: 'inherit',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

interface Props {
  children: ReactNode;
}

export const DirectoryLabel = ({ children, ...other }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.labelRoot} {...other}>
      <Typography variant="body2" noWrap className={classes.labelText}>
        {children}
      </Typography>
    </div>
  );
};
