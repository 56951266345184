/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  ListItem,
  Button,
  Collapse,
  Typography,
  colors
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    padding: theme.spacing(0, 1, 0, 0),
    '& a': {
      outline: 'none'
    }
  },
  toggleableItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    display: 'flex',
    color: colors.blueGrey[800],
    borderRadius: 0,
    padding: '12px 0',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0
  },
  toggleableButton: {
    paddingLeft: 0,
    flexGrow: 1
  },
  children: {
    padding: theme.spacing(0, 1, 0, 3)
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '12px 0',
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
      paddingLeft: theme.spacing(3)
    }
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 24,
    width: 24
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const { t, i18n } = useTranslation('navigation');
  const lang = i18n.language.split('-')[0];

  const handleToggle = () => {
    setOpen(open => !open);
  };

  if (children) {
    return (
      <React.Fragment>
        <ListItem
          {...rest}
          button
          className={clsx(classes.item, classes.toggleableItem, className)}
          disableGutters>
          <Typography
            variant="button"
            className={classes.button}
            onClick={handleToggle}>
            {open ? (
              <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
            ) : (
              <ChevronRightIcon
                className={classes.expandIcon}
                color="inherit"
              />
            )}
          </Typography>
          <Typography
            component={RouterLink}
            to={href}
            variant="button"
            className={clsx(classes.button, classes.toggleableButton)}>
            {Icon && <Icon className={classes.icon} />}
            {t(title)}
          </Typography>
        </ListItem>
        <Collapse className={classes.children} in={open}>
          {children}
        </Collapse>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Divider />
        <ListItem
          {...rest}
          button
          className={clsx(classes.itemLeaf, className)}
          disableGutters>
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={CustomRouterLink}
            exact
            to={`/${lang}${href}`}>
            {Icon && <Icon className={classes.icon} />}
            {t(title)}
            {Label && (
              <span className={classes.label}>
                <Label />
              </span>
            )}
          </Button>
        </ListItem>
      </React.Fragment>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;
