import React from 'react';
import { useUiModal } from '../../../../modules/ui/hooks';
import {
  CREATE_DIRECTORY_MODAL_ID,
  BASKET_VIEW_MODAL_ID
} from '../../../../modules/ui/constants/modal';
import { AddDirectoryModal } from '../../../../views/MyDisk/components/Form';
import { BasketViewModal } from '../../../../modules/basket/components/BasketViewModal';

function DashboardModals() {
  const { activeModal, hide } = useUiModal();

  const handleModalClose = () => {
    hide();
  };

  switch (activeModal) {
    case CREATE_DIRECTORY_MODAL_ID: {
      return <AddDirectoryModal open={true} onClose={handleModalClose} />;
    }
    case BASKET_VIEW_MODAL_ID: {
      return <BasketViewModal open={true} onClose={handleModalClose} />;
    }
    default:
      return null;
  }
}

export default DashboardModals;
