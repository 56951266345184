import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import MyDiskView from './views/MyDisk';
import SearchView from './views/MyDisk/Search';
import localeConfig from 'config/locale';
import { AuthGuard, GuestGuard } from './components';

const route = (path: string) => {
  const locales = localeConfig.locales.join('|');

  return `/:lang(${locales})?${path}`;
};

export const generateRoutes = (options: any) => [
  {
    path: route('/auth'),
    component: (props: any) => (
      <GuestGuard>
        <AuthLayout {...props} />
      </GuestGuard>
    ),
    routes: [
      {
        path: route('/auth/login'),
        exact: true,
        component: lazy(() => import('./views/Login'))
      },
      {
        path: route('/auth/password-reset'),
        exact: true,
        component: lazy(() => import('./views/PasswordReset'))
      },
      {
        path: route('/auth/password-reset/:token'),
        component: lazy(() => import('./views/PasswordReset'))
      },
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  },
  {
    path: route('/errors'),
    component: ErrorLayout,
    routes: [
      {
        path: route('/errors/401'),
        exact: true,
        component: lazy(() => import('./views/Error401'))
      },
      {
        path: route('/errors/404'),
        exact: true,
        component: lazy(() => import('./views/Error404'))
      },
      {
        path: route('/errors/500'),
        exact: true,
        component: lazy(() => import('./views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  },
  {
    route: '*',
    component: (props: any) => (
      <AuthGuard>
        <DashboardLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: route('/'),
        exact: true,
        component: MyDiskView
      },
      {
        path: route('/search/:search'),
        exact: true,
        component: SearchView
      },
      {
        path: route('/directory/:id(\\d+)'),
        exact: true,
        component: MyDiskView
      },
      {
        path: route('/directory/:id(\\w+)'),
        exact: true,
        component: () => <Redirect to="/" />
      },
      {
        path: route('/users'),
        exact: true,
        component: lazy(() => import('./views/Users'))
      },
      {
        path: route('/users/:tab'),
        component: lazy(() => import('./views/Users'))
      },
      {
        path: route('/user/:id'),
        component: lazy(() => import('./views/UserDetails'))
      },
      {
        path: route('/notifications'),
        exact: true,
        component: lazy(() => import('./views/Feedback'))
      },
      {
        path: route('/settings'),
        component: lazy(() => import('./views/Settings'))
      },
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  }
];
