import React from 'react';
import { useTranslation } from 'utils/translation';
import { Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import notFoundImage from 'assets/images/not-found.svg';
import serverErrorImage from 'assets/images/server-error.svg';
import { Link as RouterLink } from 'react-router-dom';
import { UIError } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(80),
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  image: {
    maxWidth: theme.spacing(30)
  },
  title: {
    margin: theme.spacing(2, 0)
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface Props {
  error: UIError;
}

const UIErrorView: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const contents = [
    {
      supports: [404, 403],
      message:
        "Directory doesn't exists or you don't have permission to view it",
      image: notFoundImage
    },
    {
      message: 'Unexpected error occurs',
      image: serverErrorImage
    }
  ];

  const content = contents.find(item => {
    if (!item.supports) {
      return true;
    }

    return Array.from(item.supports).includes(error);
  });

  if (!content) {
    return null;
  }

  return (
    <div className={classes.root}>
      <img className={classes.image} alt="" src={content.image} />
      <Typography className={classes.title} variant="h3">
        {t(content.message)}
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/"
          variant="outlined">
          {t('Back')}
        </Button>
      </div>
    </div>
  );
};

export { UIErrorView };
