import React, { useState } from 'react';
import {
  CircularProgress,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import FolderIcon from '@material-ui/icons/Folder';
import CloseIcon from '@material-ui/icons/Close';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import LayersIcon from '@material-ui/icons/Layers';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'utils/translation';
import {
  useBasketActions,
  useIsBasketLoading,
  useIsDirInBasket
} from '../../../../modules/basket/hooks';
import { Dir } from '../../../../modules/dirs';
import {
  useDirsActions,
  useIsDirDownloading
} from '../../../../modules/dirs/hooks';
import { DirPermissionsModal, DirectoryType } from '../Form';
import {
  transformDirectoryToResource,
  useResourceModal
} from '../../../../modules/resources';
import { ResourceModal } from '../../../../modules/ui';
import {
  useUserIsAdmin,
  useUserIsSuperAdmin
} from '../../../../modules/auth/hooks';
import bytesToSize from '../../../../utils/bytesToSize';
import clsx from 'clsx';
import { DateFormat } from '../../../../components';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1.5, 2)
  },
  preview: {
    marginBottom: theme.spacing(2)
  },
  dirIcon: {
    width: 60,
    height: 60,
    margin: '0 auto',
    color: theme.palette.text.secondary
  },
  title: {
    margin: theme.spacing(2, 0),
    fontWeight: 'bold'
  }
}));

interface Props {
  dir: Dir;
  onNavigateClick(dir: Dir): void;
}

const Loader = () => <CircularProgress size={24} />;

const DirectoryContents: React.FC<Props> = ({ dir, onNavigateClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const isAdmin = useUserIsAdmin();
  const isSuperAdmin = useUserIsSuperAdmin();
  const { addDirectory, removeDirectory } = useBasketActions();
  const { downloadDir } = useDirsActions();
  const { openModal } = useResourceModal();
  const isInBasket = useIsDirInBasket(dir.id);
  const isBasketLoading = useIsBasketLoading();
  const isDirDownloading = useIsDirDownloading();
  const [permissionsModalActive, setPermissionsModalActive] = useState(false);

  const openPermissionsModal = () => {
    setPermissionsModalActive(true);
  };

  const closePermissionsModal = () => {
    setPermissionsModalActive(false);
  };

  const handleAddDirectoryToBasket = () => {
    addDirectory({
      dir: {
        id: dir.id
      }
    });
  };

  const handleRemoveDirectoryFromBasket = () => {
    removeDirectory({
      dir: {
        id: dir.id
      }
    });
  };

  const handleDirectoryDownload = () => {
    downloadDir(dir);
  };

  const handleIntelligenceLayerClick = () => {
    openModal(
      ResourceModal.IntelligentLayer,
      transformDirectoryToResource(dir)
    );
  };

  return (
    <>
      <List className={classes.list} key={dir.id}>
        <ListItem className={clsx(classes.item, classes.preview)}>
          <FolderIcon className={classes.dirIcon} />
        </ListItem>
        <Divider />

        <Hidden smUp>
          <ListItem
            button
            className={classes.item}
            onClick={() => onNavigateClick(dir)}>
            <ListItemIcon>
              <FolderOpenIcon />
            </ListItemIcon>
            <ListItemText primary={t('Open')} />
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
          </ListItem>
          <Divider />
        </Hidden>

        {isAdmin && (
          <>
            <ListItem
              button
              className={classes.item}
              onClick={handleIntelligenceLayerClick}>
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary={t('Intelligence Layer')} />
            </ListItem>
            <Divider />
          </>
        )}
        {isSuperAdmin && (
          <>
            <ListItem
              button
              className={classes.item}
              onClick={openPermissionsModal}>
              <ListItemIcon>
                <AccessibilityNewIcon />
              </ListItemIcon>
              <ListItemText primary={t('Permissions')} />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem
          button
          className={classes.item}
          onClick={handleDirectoryDownload}
          disabled={isDirDownloading}>
          <ListItemIcon>
            {isDirDownloading ? <Loader /> : <GetAppIcon />}
          </ListItemIcon>
          <ListItemText primary={t('Download')} />
        </ListItem>
        <Divider />
        {isInBasket ? (
          <ListItem
            button
            className={classes.item}
            onClick={handleRemoveDirectoryFromBasket}
            disabled={isBasketLoading}>
            <ListItemIcon>
              <CloseIcon />
            </ListItemIcon>
            <ListItemText primary={t('Remove From Collection')} />
          </ListItem>
        ) : (
          <ListItem
            button
            className={classes.item}
            onClick={handleAddDirectoryToBasket}
            disabled={isBasketLoading}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={t('Add To Collection')} />
          </ListItem>
        )}
        <Divider />
      </List>
      <List className={classes.list}>
        <Typography variant="h4" className={classes.title}>
          {t('Directory info')}
        </Typography>
        <Table size="small">
          <TableBody>
            {isAdmin && (
              <TableRow>
                <TableCell variant="head">{t('Type')}</TableCell>
                <TableCell>
                  <DirectoryType dir={dir} />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell variant="head">{t('Size')}</TableCell>
              <TableCell>
                {typeof dir.filesSize !== 'undefined'
                  ? bytesToSize(dir.filesSize)
                  : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Created on')}</TableCell>
              <TableCell>
                <DateFormat date={dir.createdAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Last modify')}</TableCell>
              <TableCell>
                <DateFormat date={dir.updatedAt} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </List>
      {permissionsModalActive && (
        <DirPermissionsModal
          open={true}
          onClose={closePermissionsModal}
          dir={dir}
        />
      )}
    </>
  );
};

export { DirectoryContents };
