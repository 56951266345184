import React, { memo } from 'react';
import { TableEditBar, withConfirm } from 'components';
import { Button, ClickAwayListener } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GridView } from './GridView';
import { TableView } from './TableView';
import { AbstractResultsProps } from './types';
import { useSelection } from '../../../../utils/use-selection';
import {
  ContextMenuState,
  isDir,
  isFile,
  Resource
} from '../../../../modules/resources';
import { useKeyboardKey } from '../../../../utils/use-keyboard-key';
import { ViewMode } from '../../../../modules/ui/types';
import { Dir } from '../../../../modules/dirs';
import { useDirsActions } from '../../../../modules/dirs/hooks';
import { useFilesActions } from '../../../../modules/files/hooks';
import useRouter from '../../../../utils/useRouter';
import useResourceContextMenu from '../../../../modules/resources/hooks/use-resource-context-menu';
import ContextMenuContainer from '../ContextMenu/ContextMenuContainer';
import { useBasketActions } from '../../../../modules/basket/hooks';

import { useTranslation } from 'utils/translation';
import { WithConfirmProps } from '../../../../components/Confirmation/withConfirm';
import { shallowEqual } from 'react-redux';

interface ResultViewModeProps extends WithConfirmProps {
  viewMode: ViewMode;
}

type Props = AbstractResultsProps & ResultViewModeProps;

export const ResultsView = ({
  viewMode,
  resources,
  onClick,
  confirm,
  ...rest
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation('myDisk');
  const { deleteDir, downloadDir } = useDirsActions();
  const { addDirectory, addFile } = useBasketActions();
  const { deleteFile, download } = useFilesActions();
  const View = viewMode === ViewMode.Table ? TableView : GridView;
  const { isKeyDown: isShiftDown } = useKeyboardKey('Shift');
  const { isKeyDown: isCtrlDown } = useKeyboardKey('Control');
  const selectionProps = useSelection<Resource>(resources, item => i =>
    i.id === item.id && i.type === item.type
  );
  const {
    openContextMenu,
    closeContextMenu,
    isOpen: isContextMenuOpen,
    x,
    y,
    resource
  } = useResourceContextMenu();

  const handleItemRightClick = (data: ContextMenuState) => {
    openContextMenu(data);
  };

  const handleWrapperClick = () => {
    if (!(isShiftDown || isCtrlDown)) {
      selectionProps.deselectAll();
    }
  };

  const handleAwayClick = () => {
    if (!(isShiftDown || isCtrlDown)) {
      selectionProps.deselectAll();
    }
  };

  const handleItemClick = (item: Resource) => {
    if (isShiftDown || isCtrlDown) {
      selectionProps.selectOne(item);
    } else {
      selectionProps.deselectAll();
      selectionProps.setLastSelected(item);
      onClick(item);
    }
  };

  const handleItemDoubleClick = (item: Resource) => {
    if (selectionProps.selectedItems.length > 0) {
      return;
    }

    if (isDir(item)) {
      router.history.push(`/directory/${item.id}`);
    }
  };

  const handleDeleteMany = (resources: Resource[]) => {
    const dirs = resources.filter(isDir);
    dirs.map((dir: Dir) => deleteDir(dir));

    const files = resources.filter(isFile);
    files.map(file => deleteFile(file));
  };

  const handleAddToBasketMany = (resources: Resource[]) => {
    const dirs = resources.filter(isDir);
    dirs.map((dir: Dir) =>
      addDirectory({
        dir
      })
    );

    const files = resources.filter(isFile);
    files.map(file =>
      addFile({
        file
      })
    );
  };

  const handleDownloadMany = (resources: Resource[]) => {
    const dirs = resources.filter(isDir);
    dirs.map((dir: Dir) => downloadDir(dir));

    const files = resources.filter(isFile);
    files.map(file => download(file));
  };

  const downloadSelected = () => {
    handleDownloadMany(selectionProps.selectedItems);
    selectionProps.deselectAll();
  };

  const addToBasketSelected = () => {
    handleAddToBasketMany(selectionProps.selectedItems);
    selectionProps.deselectAll();
  };

  const deleteSelected = () => {
    handleDeleteMany(selectionProps.selectedItems);
    selectionProps.deselectAll();
  };

  const handleDeleteManyWithConfirm = () => {
    confirm(deleteSelected)();
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <div onClick={handleWrapperClick}>
        <View
          resources={resources}
          onDeleteMany={handleDeleteMany}
          onClick={handleItemClick}
          onDoubleClick={handleItemDoubleClick}
          onItemRightClick={handleItemRightClick}
          {...selectionProps}
          {...rest}
        />
        <ContextMenuContainer
          open={isContextMenuOpen}
          onClose={closeContextMenu}
          resource={resource as Resource}
          x={x as number}
          y={y as number}
        />
        <TableEditBar
          selected={selectionProps.selectedItems}
          extraActions={() => (
            <>
              <Button onClick={addToBasketSelected}>
                <InboxIcon />
                {t('Add To Collection')}
              </Button>
              <Button onClick={downloadSelected}>
                <GetAppIcon />
                {t('Download')}
              </Button>
            </>
          )}
          onDelete={handleDeleteManyWithConfirm}
        />
      </div>
    </ClickAwayListener>
  );
};

export default memo(withConfirm<Props>(ResultsView), shallowEqual);
