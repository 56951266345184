import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import bytesToSize from '../../../utils/bytesToSize';
import { UploadProgress } from './UploadProgress';
import { useUploadingFile } from '../hooks';

interface Props {
  fileId: string;
}

const FileUploadItem = (props: Props) => {
  const file = useUploadingFile(props.fileId);
  const { progress } = file;

  if (!file) {
    return null;
  }

  return (
    <ListItem>
      <ListItemIcon>
        <UploadProgress progress={progress} />
      </ListItemIcon>
      <ListItemText
        primary={file.name}
        primaryTypographyProps={{ variant: 'h5' }}
        secondary={bytesToSize(file.size)}
      />
    </ListItem>
  );
};

export { FileUploadItem };
