import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { PDFViewer, PhotoViewer } from '../../../../../components';
import { File } from '../../../../../modules/files';
import { makeStyles } from '@material-ui/core/styles';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 !important',
    height: '95vh',
    alignItems: 'center'
  },
  paper: {
    overflow: 'hidden',
    borderRadius: 0
  }
}));

interface PreviewProps {
  onClose(): void;
  src: string;
}

const DocumentPreviewModal: React.FC<PreviewProps> = ({ onClose, src }) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      PaperProps={{ className: classes.paper }}>
      <DialogContent className={classes.content}>
        <PDFViewer url={src} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

interface Props {
  file: File;
  onClose(): void;
}

const PreviewModal: React.FC<Props> = ({ file, onClose }) => {
  if (!file.previewUrl) {
    return null;
  }

  if (['jpg', 'jpeg', 'png', 'gif'].includes(file.type)) {
    return <PhotoViewer onClose={onClose} src={file.previewUrl} />;
  }

  if (['pdf'].includes(file.type)) {
    return <DocumentPreviewModal onClose={onClose} src={file.previewUrl} />;
  }

  return null;
};

export { PreviewModal };
