/* eslint-disable quotes */
import React, { useEffect } from 'react';
import PDFObject from 'pdfobject';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'utils/translation';
import { Theme } from '../../theme';
import { ModalClose } from '../index';

interface Props {
  url: string;
  onClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  fallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4)
    }
  },
  title: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 0)
    }
  }
}));

const Fallback: React.FC<Props> = ({ url, onClose }) => {
  const { t } = useTranslation(['global', 'myDisk']);
  const classes = useStyles();

  return (
    <div className={classes.fallback}>
      <ModalClose onClose={onClose} />
      <Typography variant="h4" align="center" className={classes.title}>
        {t("Your browser doesn't support PDF previews")}
      </Typography>
      <Button color="primary" variant="outlined" href={url}>
        {t('myDisk:Download')}
      </Button>
    </div>
  );
};

const PDFViewer: React.FC<Props> = ({ url, onClose }) => {
  const classes = useStyles();
  const isSupported = PDFObject.supportsPDFs;

  useEffect(() => {
    if (isSupported) {
      PDFObject.embed(url, '#pdf-preview-container');
    }
  }, [url, isSupported]);

  return (
    <div className={classes.root} id="pdf-preview-container">
      {!isSupported && <Fallback url={url} onClose={onClose} />}
    </div>
  );
};

export default PDFViewer;
