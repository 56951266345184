/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import get from 'lodash/get';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, IconButton, Toolbar, Hidden, Input } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import useRouter from 'utils/useRouter';
import { matchPath } from 'react-router';
import { LocaleSwitcher } from 'components';
import { CurrentUserActions } from '../../../../modules/auth';
import { useAppSettings } from '../../../../modules/ui/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  toolbar: {
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center'
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  logoWrapper: {
    width: 202,
    height: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center'
  },
  logo: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 202,
      maxHeight: theme.spacing(8)
    }
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 400,
    height: 36,
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  mobileMenuIcon: {
    marginLeft: theme.spacing(-1.5)
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const { settings } = useAppSettings();
  const logoUrl = get(settings, 'webLogo') || '/images/logos/logo.svg';

  const classes = useStyles();
  const router = useRouter();
  let initialSearch = '';
  const result = matchPath(router.location.pathname, {
    path: '/search/:search'
  });

  if (result) {
    const {
      params: { search = '' }
    } = result;
    initialSearch = search;
  }

  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState(
    decodeURIComponent(initialSearch || '')
  );

  useEffect(() => {
    if (!router.location.pathname.startsWith('/search')) {
      setSearchValue('');
    }
  }, [router.location.pathname]);

  const updateSearchQuery = useCallback(
    debounce(query => {
      if (query) {
        router.history.push(`/search/${encodeURIComponent(query)}`);
      } else {
        router.history.push(`/`);
      }
    }, 750),
    [router]
  );

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
    updateSearchQuery(event.target.value);
  };

  return (
    <AppBar
      position="fixed"
      {...rest}
      className={clsx(classes.root, className)}
      color="primary">
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.mobileMenuIcon}
            color="inherit"
            onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <div className={classes.logoWrapper}>
            <img className={classes.logo} alt="Quodoc" src={logoUrl} />
          </div>
        </RouterLink>
        <Hidden smDown>
          <div className={classes.search} ref={searchRef}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search..."
              value={searchValue}
              autoFocus={!!initialSearch}
            />
          </div>
        </Hidden>
        <div className={classes.flexGrow} />
        <LocaleSwitcher />
        <CurrentUserActions />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
