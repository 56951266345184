import React from 'react';
import moment from 'moment';

interface Props {
  date: string;
  format?: string;
  diff?: boolean;
}

const DateFormat: React.FC<Props> = ({ date, format, diff }) => {
  let localeFormat = moment()
    .localeData()
    .longDateFormat('L');

  if (format) {
    localeFormat = format;
  }

  if (diff) {
    return <>{moment().to(date)}</>;
  }

  return <>{moment(date).format(localeFormat)}</>;
};

export default DateFormat;
