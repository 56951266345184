import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  selectRootDirs,
  selectDir,
  isDirFetching,
  selectIsFetching,
  selectDirSiblings,
  selectFormState,
  selectDirChildren,
  selectLoadedDirs,
  selectIsRootDirsLoading,
  isDirDownloading
} from './selectors';
import { dirsActions } from './actions';
import { Dir, CreateDirPayload, MoveDirPayload } from './index';
import { RootState } from '../../store';

export function useDirsActions() {
  const dispatch = useDispatch();
  const fetchDirs = (params: any = {}) =>
    dispatch(dirsActions.fetch.trigger(params));
  const preloadDirs = () => dispatch(dirsActions.preload.trigger());
  const createDir = (data: CreateDirPayload) =>
    dispatch(dirsActions.create.trigger(data));
  const readDir = (data: Pick<Dir, 'id'>) =>
    dispatch(dirsActions.read.trigger(data));
  const updateDir = (dir: Pick<Dir, 'id'> & Partial<Dir>) =>
    dispatch(dirsActions.update.trigger(dir));
  const pathUpdateDir = (dir: Pick<Dir, 'id'> & Partial<Dir>) =>
    dispatch(dirsActions.patchUpdate.trigger(dir));
  const moveDir = (dir: MoveDirPayload) =>
    dispatch(dirsActions.move.trigger(dir));
  const deleteDir = (dir: Dir) => dispatch(dirsActions.delete.trigger(dir));
  const clearErrors = () => dispatch(dirsActions.clearErrors());
  const downloadDir = (dir: Dir) => dispatch(dirsActions.download.trigger(dir));

  return {
    fetchDirs,
    preloadDirs,
    createDir,
    readDir,
    updateDir,
    pathUpdateDir,
    moveDir,
    deleteDir,
    downloadDir,
    clearErrors
  };
}

export function useSingleDir(id: number) {
  const dir = useSelector(
    state => selectDir(state as RootState, id),
    shallowEqual
  ) as Nullable<Dir>;
  const isFetching = useSelector(
    state => isDirFetching(state as RootState, id),
    shallowEqual
  );

  return {
    dir,
    isFetching
  };
}

export function useDirs(dirId?: number) {
  const dirsSelector = dirId
    ? (state: RootState) => selectDirChildren(state as RootState, dirId)
    : selectRootDirs;

  const dirs = useSelector(dirsSelector, shallowEqual);
  const isFetching = useSelector(selectIsFetching, shallowEqual);

  return {
    dirs,
    isFetching
  };
}

export function useDirSiblings(id: number): Dir[] {
  return useSelector(
    (state: RootState) => selectDirSiblings(state, id),
    shallowEqual
  );
}

export function useDirsFormState() {
  return useSelector(selectFormState, shallowEqual);
}

export function useLoadedDirs() {
  const dirs = useSelector(selectLoadedDirs, shallowEqual) as Dir[];
  const isLoading = useSelector(selectIsRootDirsLoading, shallowEqual);

  return {
    dirs,
    isLoading
  };
}

export function useIsDirDownloading() {
  return useSelector(isDirDownloading, shallowEqual);
}
