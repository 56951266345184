import { useDispatch } from 'react-redux';
import { filesActions } from '../actions';
import { File } from '../index';

export function useFilesActions() {
  const dispatch = useDispatch();
  const fetchFiles = (params: any = {}) =>
    dispatch(filesActions.fetch.trigger(params));
  const readFile = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.read.trigger(file));
  const createFile = (data: any) => dispatch(filesActions.create.trigger(data));
  const addToUploadQueue = (items: any[]) =>
    dispatch(filesActions.addToUploadQueue(items));
  const updateFile = (file: Pick<File, 'id'> & Partial<File>) =>
    dispatch(filesActions.update.trigger(file));
  const deleteFile = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.delete.trigger(file));
  const getThumb = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.getThumb.trigger(file));
  const getPreview = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.getPreview.trigger(file));
  const download = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.download.trigger(file));
  const share = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.share.trigger(file));

  const clearErrors = () => dispatch(filesActions.clearErrors());

  const moveFile = ({
    file,
    dirId
  }: {
    file: Pick<File, 'id'> & Partial<File>;
    dirId: number;
  }) =>
    dispatch(
      filesActions.move.trigger({
        file,
        dirId
      })
    );

  return {
    fetchFiles,
    readFile,
    updateFile,
    moveFile,
    createFile,
    deleteFile,
    getThumb,
    getPreview,
    clearErrors,
    download,
    share,
    addToUploadQueue
  };
}
