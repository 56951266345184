import { QueuedFile, UploadedFile } from '../index';
import { handleActions } from 'redux-actions';
import { filesActions } from '../actions';
import produce from 'immer';

interface UploadState {
  items: {
    [key: string]: UploadedFile;
  };
}

const initialState: UploadState = {
  items: {}
};

const uploadReducer = handleActions<UploadState, UploadedFile | any>(
  {
    [filesActions.ADD_TO_UPLOAD_QUEUE]: (state, action) => {
      const items = Array.from(action.payload) as QueuedFile[];
      let counter = 0;
      let time = new Date().getTime();

      return produce(state, draft => {
        items.forEach(file => {
          counter++;
          draft.items[file.id] = {
            id: file.id,
            progress: 0,
            name: file.name,
            size: file.size,
            createdAt: time + counter
          };
        });
      });
    },

    [filesActions.UPLOAD_PROGRESS]: (state, action) => {
      const { id, progress } = action.payload;

      if (state.items[id]) {
        return produce(state, draft => {
          draft.items[id].progress = progress;
        });
      }

      return state;
    },
    [filesActions.create.FULFILL]: (state, action) => {
      const { id } = action.payload;

      if (state.items[id]) {
        return produce(state, draft => {
          delete draft.items[id];
        });
      }

      return state;
    },
    [filesActions.create.FAILURE]: (state, action) => {
      const { id, error } = action.payload;

      if (state.items[id]) {
        return produce(state, draft => {
          draft.items[id].error = error;
        });
      }

      return state;
    }
  },
  initialState
);

export { uploadReducer };
