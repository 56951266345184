import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import localeConfig from 'config/locale';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  button: {
    color: 'inherit'
  }
}));

export default function LocaleSwitcher() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleLocaleChange(locale: string) {
    await moment.locale(locale);
    await i18n.changeLanguage(locale);
    handleClose();
  }

  const { i18n } = useTranslation();

  return (
    <div>
      <Button
        className={classes.button}
        aria-controls="app-locale"
        aria-haspopup="true"
        onClick={handleClick}>
        {i18n.language.split('-')[0]}
      </Button>
      <Menu
        id="app-locale"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {localeConfig.locales.map(locale => (
          <MenuItem onClick={() => handleLocaleChange(locale)} key={locale}>
            {locale.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
