import React from 'react';
import { FieldProps } from './types';
import { FormControl, TextField } from '@material-ui/core';
import pick from 'lodash/pick';

const LongTextField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  onChange,
  settings = {}
}) => {
  const attributes = pick(settings, [
    'disabled',
    'maxLength',
    'rows',
    'cols',
    'placeholder'
  ]);

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      <TextField
        error={!!errorMessage}
        helperText={errorMessage}
        name={name}
        variant="outlined"
        rows={4}
        rowsMax={20}
        multiline
        fullWidth
        disabled={attributes.disabled}
        inputProps={attributes}
        value={value || ''}
        onChange={onChange}
      />
    </FormControl>
  );
};

export { LongTextField };
