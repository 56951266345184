import React from 'react';
import { useUploadingFileListIds } from '../hooks';
import Card from '@material-ui/core/Card';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Divider, List, Typography } from '@material-ui/core';
import { FileUploadItem } from './FileUploadItem';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    queueContainer: {
      position: 'absolute',
      width: 400,
      left: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 100
    },
    header: {
      padding: theme.spacing(2)
    },
    list: {
      maxHeight: '40vh'
    }
  })
);

const BaseList = () => {
  const classes = useStyles();
  const fileIds = useUploadingFileListIds();

  if (!fileIds.length) {
    return null;
  }

  const chunk = fileIds.slice(0, 5);

  return (
    <div className={classes.queueContainer}>
      <Card>
        <Typography variant="h5" className={classes.header}>
          Uploading {fileIds.length} items
        </Typography>
        <Divider />
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List className={classes.list}>
            {chunk.map((fileId: string) => (
              <FileUploadItem fileId={fileId} key={fileId} />
            ))}
          </List>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

const UploadingList = React.memo(BaseList, () => true);

export { UploadingList };
