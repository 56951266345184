import React from 'react';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewListIcon from '@material-ui/icons/ViewList';
import { IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ViewMode } from '../../../../modules/ui/types';

interface Props {
  onChange: (value: ViewMode) => void;
  value: ViewMode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1)
  }
}));

export const ViewSwitcher = ({ onChange, value }: Props) => {
  const classes = useStyles();
  const Icon = value === ViewMode.Grid ? ViewComfyIcon : ViewListIcon;
  const handleSwitch = () => {
    return value === ViewMode.Grid
      ? onChange(ViewMode.Table)
      : onChange(ViewMode.Grid);
  };

  return (
    <IconButton className={classes.root} onClick={handleSwitch} size="small">
      <Icon />
    </IconButton>
  );
};
