import React from 'react';
import ContextMenu from './ContextMenu';
import {
  isDir,
  isFile,
  Resource,
  useResourceModal
} from '../../../../modules/resources';
import { useBasketActions } from '../../../../modules/basket/hooks';
import { useDirsActions } from '../../../../modules/dirs/hooks';
import { useFilesActions } from '../../../../modules/files/hooks';
import { ResourceModal } from '../../../../modules/ui';
import { withConfirm } from '../../../../components';
import { WithConfirmProps } from '../../../../components/Confirmation/withConfirm';

interface Props extends WithConfirmProps {
  resource: Resource;
  x: number;
  y: number;
  open: boolean;
  onClose(): void;
}

const ContextMenuContainer: React.FC<Props> = ({
  resource,
  x,
  y,
  open,
  onClose,
  confirm
}) => {
  const { openModal } = useResourceModal();
  const { deleteDir } = useDirsActions();
  const { deleteFile } = useFilesActions();
  const {
    addDirectory,
    removeDirectory,
    addFile,
    removeFile
  } = useBasketActions();
  const { downloadDir } = useDirsActions();
  const { download, share } = useFilesActions();

  const handleRenameClick = (resource: Resource) => {
    openModal(ResourceModal.Edit, resource);
  };

  const handleMoveClick = (resource: Resource) => {
    openModal(ResourceModal.Move, resource);
  };

  const handleDeleteClick = (resource: Resource) => {
    if (isDir(resource)) {
      deleteDir(resource);
    }

    if (isFile(resource)) {
      deleteFile(resource);
    }
  };

  const handlePermissionsClick = (resource: Resource) => {
    openModal(ResourceModal.Permissions, resource);
  };

  const handleAddToBasket = (resource: Resource) => {
    if (isDir(resource)) {
      addDirectory({ dir: resource });
    }

    if (isFile(resource)) {
      addFile({ file: resource });
    }
  };

  const handleRemoveFromBasket = (resource: Resource) => {
    if (isDir(resource)) {
      removeDirectory({ dir: resource });
    }

    if (isFile(resource)) {
      removeFile({ file: resource });
    }
  };

  const handleDownload = (resource: Resource) => {
    if (isDir(resource)) {
      downloadDir(resource);
    }

    if (isFile(resource)) {
      download(resource);
    }
  };

  const handleFileShare = (resource: Resource) => {
    if (isFile(resource)) {
      share(resource);
      openModal(ResourceModal.Share, resource);
    }
  };

  const handleIntelligentLayer = (resource: Resource) => {
    openModal(ResourceModal.IntelligentLayer, resource);
  };

  const handleDeleteWithConfirm = (resource: Resource) => {
    const confirmation = confirm(() => {
      handleDeleteClick(resource);
    });

    confirmation();
  };

  if (!open) {
    return null;
  }

  return (
    <ContextMenu
      resource={resource as Resource}
      x={x as number}
      y={y as number}
      onClose={onClose}
      onRenameClick={handleRenameClick}
      onMoveClick={handleMoveClick}
      onDeleteClick={handleDeleteWithConfirm}
      onDownloadClick={handleDownload}
      onShareClick={handleFileShare}
      onIntelligentLayerClick={handleIntelligentLayer}
      onPermissionsClick={handlePermissionsClick}
      onAddToBasketClick={handleAddToBasket}
      onRemoveFromBasketClick={handleRemoveFromBasket}
    />
  );
};

export default withConfirm<Props>(ContextMenuContainer);
