import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from './selectors';
import {
  logout as logoutAction,
  fetchUser as fetchUserAction
} from './actions';
import { useCallback } from 'react';
import { useApiCall } from '../@common/hooks';
import {
  changePasswordWithToken,
  requestPasswordReset,
  verifyResetPasswordToken
} from './service/api';
import { ROLES } from './constants';

export function useAuthActions() {
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(logoutAction.trigger()), [
    dispatch
  ]);

  const fetchUser = useCallback(
    () =>
      dispatch(
        fetchUserAction.trigger({
          silent: true
        })
      ),
    [dispatch]
  );

  return {
    logout,
    fetchUser
  };
}

export function useCurrentUser() {
  return useSelector(selectCurrentUser, shallowEqual);
}

export function useUserHasRoles(roles: string[]) {
  const user = useCurrentUser();

  if (!user) {
    return false;
  }

  let access = false;
  roles.forEach(role => {
    if (Array.from(user.roles || []).includes(role)) {
      access = true;
    }
  });

  return access;
}

export function useUserIsAdmin() {
  const roles = [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN];
  return useUserHasRoles(roles);
}

export function useUserIsSuperAdmin() {
  const roles = [ROLES.ROLE_SUPER_ADMIN];
  return useUserHasRoles(roles);
}

export function usePasswordResetRequest() {
  return useApiCall({
    provider: requestPasswordReset
  });
}

export function useVerifyPasswordTokenRequest() {
  return useApiCall({
    provider: verifyResetPasswordToken,
    initialIsLoading: true
  });
}

export function useChangePasswordWithTokenRequest() {
  return useApiCall({
    provider: changePasswordWithToken
  });
}
