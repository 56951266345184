import { useState } from 'react';
import get from 'lodash/get';

export enum Direction {
  ASC = 'asc',
  DESC = 'desc'
}

interface SortableArgs<T> {
  direction?: Direction;
  field?: T;
}

function compareByValue(val1: any, val2: any) {
  if (val1 > val2) {
    return 1;
  }

  if (val1 < val2) {
    return -1;
  }

  return 0;
}

export function compareDates<T>(a: T, b: T, field: keyof T) {
  const valA = new Date(get(a, field, 0) as any).getTime();
  const valB = new Date(get(b, field, 0) as any).getTime();

  return compareByValue(valA, valB);
}

export function compareValues<T>(a: T, b: T, field: keyof T | string) {
  const valA = get(a, field, 0);
  const valB = get(b, field, 0);

  return compareByValue(valA, valB);
}

export function localeCompare<T>(a: T, b: T, field: keyof T | string) {
  const valA = get(a, field, '')
    .toString()
    .toLowerCase();
  const valB = get(b, field, '')
    .toString()
    .toLowerCase();

  return valA.localeCompare(valB);
}

export function useSortable<T>({
  direction: initialDirection = Direction.ASC,
  field: initialField
}: SortableArgs<T> = {}) {
  const [direction, setDirection] = useState<Direction>(initialDirection);
  const [field, setSortableField] = useState<T | undefined>(initialField);

  const isActive = (attribute: T) => attribute === field;

  const setField = (attribute: T) => {
    if (field !== attribute) {
      setSortableField(attribute);
    }

    setDirection(direction === Direction.ASC ? Direction.DESC : Direction.ASC);
  };

  return {
    direction,
    setDirection,
    field,
    setField,
    isActive
  };
}
