import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = (options: any) => {
  return makeStyles((theme: Theme) => ({
    root: {
      height: '100%'
    },
    toolbar: {
      position: 'fixed',
      zIndex: 2,
      left: 0,
      right: options.isOpen ? options.width : 0,
      backgroundColor: theme.palette.white,
      height: theme.spacing(8) + 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
      justifyContent: 'space-between',
      [theme.breakpoints.up('lg')]: {
        left: options.sidebarWidth
      }
    },
    container: {
      width: '100%',
      height: '100%',
      willChange: 'width',
      [theme.breakpoints.up('lg')]: {
        width: options.isOpen ? `calc(100% - ${options.width}px)` : '100%',
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      }
    },
    content: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(1),
      maxHeight: 'calc(100% - 64px)',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(3)
      }
    },
    scrollbarContainer: {
      height: 'calc(100vh - 64px)'
    }
  }));
};

type Args = {
  isOpen: boolean;
};

export default function useDiskStyles({ isOpen }: Args) {
  const drawerOptions = {
    width: 270,
    sidebarWidth: 250,
    offsetTop: 64,
    isOpen
  };

  return {
    classes: useStyles(drawerOptions)(),
    drawerOptions
  };
}
