import React from 'react';
import { FieldProps } from './types';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const SelectField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  multiple,
  onChange,
  settings = {}
}) => {
  const options = Array.from(settings.options || []) as any[];
  const defaultValue = multiple ? [] : '';

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Select
        multiple={multiple}
        name={name}
        value={value || defaultValue}
        onChange={onChange}>
        {options.map((option, index) => (
          <MenuItem disabled={option.disabled} key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { SelectField };
