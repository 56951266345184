import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import { ROLES } from '../../../../modules/auth';

export default settings => [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Users',
        href: '/users',
        icon: PeopleIcon,
        authRoles: [ROLES.ROLE_SUPER_ADMIN]
      },
      {
        title: 'Notifications',
        href: '/notifications',
        icon: NotificationsIcon,
        authRoles: [ROLES.ROLE_SUPER_ADMIN]
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        authRoles: [ROLES.ROLE_SUPER_ADMIN]
      }
    ]
  }
];
