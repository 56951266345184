import {
  DirResource,
  FileResource,
  BaseResource,
  RESOURCE_TYPE,
  Resource
} from './index';
import { Dir } from '../dirs';
import { File } from '../files';

export function isDir(resource: BaseResource): resource is DirResource {
  return resource && resource.type === RESOURCE_TYPE.DIRECTORY;
}

export function isFile(resource: BaseResource): resource is FileResource {
  return resource && resource.type === RESOURCE_TYPE.FILE;
}

interface MapResourceArgs {
  dirs: Dir[];
  files: File[];
}

export function transformDirectoryToResource(dir: Dir): Resource {
  return {
    ...dir,
    type: RESOURCE_TYPE.DIRECTORY
  };
}

export function transformFileToResource(file: File): Resource {
  return {
    ...file,
    fileType: file.type,
    title: file.filename,
    type: RESOURCE_TYPE.FILE
  };
}

export function mapResources({ dirs, files }: MapResourceArgs) {
  return [
    // Directories
    ...Array.from(dirs || []).map(transformDirectoryToResource),
    // Files
    ...Array.from(files || []).map(transformFileToResource)
  ] as Resource[];
}
