import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper, Hidden, IconButton } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import NavBarActions from './components/NavBarActions';
import { DirectoryTree } from './components/DirectoryTree';
import buildNavigation from './navigationConfig';
import NavigationListItem from '../../../../components/Navigation/components/NavigationListItem';
import FolderIcon from '@material-ui/icons/Folder';
import CloseIcon from '@material-ui/icons/Close';
import { BasketNavItem } from './components/Basket/BasketNavItem';
import { useTranslation } from 'utils/translation';
import { useAppSettings } from '../../../../modules/ui/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      position: 'fixed'
    }
  },
  content: {
    paddingTop: 0
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {},
  treeContainer: {
    maxHeight: '50vh',
    position: 'relative',
    overflow: 'hidden'
  },
  mobileNavClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1100
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const router = useRouter();
  const { settings } = useAppSettings();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const pathname = router.location.pathname || '';
  const isOpen = pathname === '/' || pathname.startsWith('/directory');
  const navigationConfig = buildNavigation(settings);

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        <NavigationListItem
          title={t('My Disk')}
          href="/"
          icon={FolderIcon}
          open={isOpen}>
          <DirectoryTree />
        </NavigationListItem>
        <BasketNavItem />
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.mobileNavClose}>
              <IconButton color="inherit" onClick={onMobileClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <NavBarActions />
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          <NavBarActions />
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
